import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { ConnectorIndex } from '../../components'
 
const ConnectorIndexPage = ({ data, location }) => {
  const connectors = data.connectors.edges.map(item => item.node)
  const destinations = data.destinations.edges.map(item => item.node)
  const bi = data.bi.edges.map(item => item.node)
  const connectorTags = data.tags.edges.map(tag => tag.node)

  const fluidImage = (path) => {
    const images = data.image.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }

  return (
    <ConnectorIndex 
      title="Data Connectors Directory"
      description="Hippobyte data connector directory. Replicate data from applications, databases, and files to a warehouse you control."
      location={location} 
      data={{ image: data.image, connectors: connectors, destinations: destinations, bi: bi }}
      tags={connectorTags}
      pageHero={{
        section: { 
          margin: { vertical: "0", horizontal: "11vw" },
        },
        main: {
          heading: {
            label: "Load data into your warehouse from your business sources.",
          },
          subHeading: {
            label: "Connectors for your most relevant business data sources. Centralize your business data into leading warehouses, then integrate with your tools of choice. Bring the power of SQL to all your applications."
          },
          style: { flex: "0 1 65%" },
        },
        aside: {
          content: (
            <Img 
              fluid={fluidImage("00-feature-connectors.png")} 
              style={{
                position: "relative",
                top: "-40px"
              }}
            />
          ),
          style: { flex: "0 1 35%" }
        }
      }}
    />
  )
}

export const pageQuery = graphql`
  query ConnectorIndex {
    image: allFile(filter: {relativePath: {in: [
      "00-feature-connectors.png"
    ]}}) {
      images: edges {
        node {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    connectors: allConnectorsJson(filter: {sourceDestination: {eq: "source"}}) {
      edges {
        node {
          title
            sourceDestination
            category
            product {
              color
              description
              connectorDescription
              url
              tags
            }
            docs {
              instructions
              asideHeading
              asideSubHeading
              aside {
                asideTitle
                asideSubTitle
                asideContent
              }
            }
          featuredimage {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    destinations: allConnectorsJson(filter: {sourceDestination: {eq: "destination"}}) {
      edges {
        node {
          title
            sourceDestination
            category
            product {
              color
              description
              connectorDescription
              url
              tags
            }
            docs {
              instructions
              asideHeading
              asideSubHeading
              aside {
                asideTitle
                asideSubTitle
                asideContent
              }
            }
          featuredimage {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    bi: allConnectorsJson(filter: {category: {eq: "Business Intelligence"}}) {
      edges {
        node {
          title
            sourceDestination
            category
            product {
              color
              description
              connectorDescription
              url
              tags
            }
            docs {
              instructions
              asideHeading
              asideSubHeading
              aside {
                asideTitle
                asideSubTitle
                asideContent
              }
            }
          featuredimage {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    tags: allTypesJson(sort: {fields: [order, title]}) {
      edges {
        node {
          order
          description
          title
        }
      }
    }
  }
`

export default ConnectorIndexPage
